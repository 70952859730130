<template>
	<b-modal
		ref="modal-petty-cash"
		@hidden="$emit('close')"
		:title="`Caja chica`"
		cancel-title="Cancelar"
		scrollable
		size="md"
	>
		<div>
			<ValidationObserver ref="formPettyCash">
				<b-row>
					<b-col sm="12">
						<ValidationProvider v-slot="{ errors }">
							<b-form-group :state="errors[0] ? false : null">
								<label for="date">
									Fecha de deposito
									<span class="text-danger">(*)</span>
								</label>
								<flat-pickr
									id="date"
									v-model="form.date"
									class="form-control bg-transparent"
									placeholder="Elige una fecha"
									:class="errors[0] ? 'error-input' : 'mb-05'"
								/>
							</b-form-group>
						</ValidationProvider>
					</b-col>
					<b-col sm="12">
						<ValidationProvider v-slot="{ errors }" rules="required">
							<b-form-group :state="errors[0] ? false : null">
								<label for="responsible">
									Responsable
									<span class="text-danger">(*)</span>
								</label>
								<v-select
									id="responsible"
									label="name"
									:reduce="(u) => u.id"
									v-model="form.responsible_id"
									:options="responsibles"
									:clearable="false"
									appendToBody
									:calculatePosition="positionDropdown"
								/>
							</b-form-group>
						</ValidationProvider>
					</b-col>
					<!-- <b-col sm="12">
						<b-form-group>
							<div class="d-flex align-items-center">
								<input id="is_add_cash" class="Opt__check" type="checkbox" v-model="form.is_add_cash" />
								<label for="is_add_cash" style="margin-left: 5px">Agregar dinero</label>
							</div>
						</b-form-group>
					</b-col> -->
					<b-col sm="12" style="margin-top: 5px" v-if="form.is_add_cash">
						<ValidationProvider v-slot="{ errors }" rules="required">
							<b-form-group :state="errors[0] ? false : null">
								<label for="amount">
									Monto
									<span class="text-danger">(*)</span>
								</label>
								<money
									id="amount"
									v-bind="money"
									v-model="form.amount"
									class="form-control"
									:class="errors[0] && save ? 'border-danger' : ''"
								/>
							</b-form-group>
						</ValidationProvider>
					</b-col>
				</b-row>
			</ValidationObserver>
		</div>
		<template #modal-footer>
			<b-button variant="danger" @click="$emit('close')">Cancelar</b-button>
			<b-button variant="primary" @click="registerPettyCash">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive"
import { Money } from "v-money"
import modalMixin from "@/mixins/modal.js"
import { ValidationProvider } from "vee-validate"
import moment from "moment"
import vSelect from "vue-select"
import PettyCashService from "@/views/amg/management/views/petty-cash/services/petty-cash.service.js"

export default {
	props: {
		pettyCash: {
			required: true,
		},
	},
	directives: { Ripple },
	components: { Money, vSelect },
	mixins: [modalMixin],
	data() {
		return {
			money: {
				decimal: ",",
				thousand: ".",
				prefix: "S/ ",
				suffix: "",
				precision: 2,
				min: 0.0,
				masked: false,
			},
			form: {
				date: moment().format("YYYY-MM-DD"),
				responsible_id: null,
				amount: 0,
				description: null,
				is_add_cash: true,
			},
			responsibles: [],
		}
	},
	async mounted() {
		this.toggleModal("modal-petty-cash")
	},
	async created() {
		try {
			await Promise.all([this.getResponsibles()])
		} catch (error) {}
	},
	methods: {
		async getResponsibles() {
			try {
				const { data } = await PettyCashService.getResponsibles()
				this.responsibles = data
			} catch (error) {
				this.responsibles = []
				throw error
			}
		},

		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},

		async registerPettyCash() {
			const validate = await this.$refs.formPettyCash.validate()
			if (!validate) return

			if (this.form.is_add_cash && !this.form.amount > 0) {
				this.showSuccessToast(
					"Monto debe ser mayor a 0",
					"warning",
					"top-right",
					"Avertencia",
					"AlertTriangleIcon"
				)
				return
			}

			const confirm = await this.showConfirmSwal({
				text: "Registrar caja chica",
			})
			if (!confirm.isConfirmed) return

			this.isPreloading()
			try {
				const registered = await PettyCashService.registerPettyCash(this.form)
				this.showSuccessToast("Caja chica registrado correctamente")
				this.$emit("registered")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
}
</script>
