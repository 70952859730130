export default [
    {
        key:"code",
        label:"Codigo",
        visible:true   
    },
    {
        key:"responsible",
        label:"Responsable",
        visible:true   
    },
    {
        key:"date",
        label:"Fecha",
        thClass:"text-center",
        visible:true
    },
    {
        key:"amount",
        label:"Deposito",
        thClass:"text-center",
        visible:true   
    },
    {
        key:"expenses",
        label:"Gasto",
        thClass:"text-center",
        visible:true   
    },
    {
        key:"balance",
        label:"Balance",
        thClass:"text-center",
        visible:true   
    },
    {
        key:"created_by",
        label:"Creado por",
        thClass:"text-center",
        visible:true   
    },
    {
        key:"updated_by",
        label:"Finalizado por",
        thClass:"text-center",
        visible:true   
    },
    {
        key:"deleted_by",
        label:"Anulado por",
        thClass:"text-center",
        visible:true   
    },
    {
        key:"actions",
        label:"Acciones",
        thClass:"text-center",
        visible:true   
    },
]