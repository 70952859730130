<template>
	<b-modal
		ref="modal-petty-cash"
		@hidden="$emit('close')"
		:title="`Cerrar caja chica`"
		cancel-title="Cancelar"
		scrollable
		size="md"
	>
		<div>
			<ValidationObserver ref="formPettyCash">
				<b-row>
					<b-col md="6">
						<b-form-group>
							<label>Fecha</label>
							<div class="d-flex align-items-center rounded pl-1" style="height: 35px;border:1px solid rgba(34, 41, 47, 0.2)">
								{{ pettyCash.code }}
							</div>
						</b-form-group>
					</b-col>

					<b-col md="6">
						<b-form-group>
							<label>Responsable</label>
							<div class="d-flex align-items-center rounded pl-1" style="height: 35px;border:1px solid rgba(34, 41, 47, 0.2)">
								{{ pettyCash.responsible }}
							</div>
						</b-form-group>
					</b-col>

					<b-col md="6">
						<b-form-group>
							<label class="text-success">Deposito ( + )</label>
							<div
								class="d-flex text-success align-items-center border-success rounded pl-1"
								style="height: 35px"
							>
								{{ pettyCash.amount | currency }}
							</div>
						</b-form-group>
					</b-col>

					<b-col md="6">
						<b-form-group>
							<label class="text-danger">Gasto ( - )</label>
							<div
								class="d-flex text-danger align-items-center border-danger rounded pl-1"
								style="height: 35px"
							>
								{{ pettyCash.expenses | currency }}
							</div>
						</b-form-group>
					</b-col>

					<b-col md="6">
						<b-form-group>
							<label class="text-primary">Saldo</label>
							<div class="d-flex text-primary align-items-center rounded border-primary pl-1" style="height: 35px;border:1px solid rgba(34, 41, 47, 0.2)">
								{{ getBalance | currency }}
							</div>
						</b-form-group>
					</b-col>
					<!-- <b-col md="6" v-if="getBalance > 0">
						<div class="d-flex align-items-center h-100">
							<input id="is_add_cash" class="Opt__check" type="checkbox" v-model="form.is_generate" />
							<label for="is_add_cash" style="margin-left: 5px">Generar caja con saldo</label>
						</div>
					</b-col> -->
				</b-row>
			</ValidationObserver>
		</div>
		<template #modal-footer>
			<div class="d-flex align-items-center">
				<b-button variant="primary" @click="closePettyCash">Finalizar</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive"
import { Money } from "v-money"
import modalMixin from "@/mixins/modal.js"
import moment from "moment"
import vSelect from "vue-select"
import PettyCashService from "@/views/amg/management/views/petty-cash/services/petty-cash.service.js"

export default {
	props: {
		pettyCash: {
			required: true,
		},
	},
	directives: { Ripple },
	components: { Money, vSelect },
	mixins: [modalMixin],
	data() {
		return {
			money: {
				decimal: ",",
				thousand: ".",
				prefix: "S/ ",
				suffix: "",
				precision: 2,
				min: 0.0,
				masked: false,
			},
			form: {
				is_generate: false,
			},
			responsibles: [],
		}
	},
	async mounted() {
		this.toggleModal("modal-petty-cash")
	},
	async created() {
		try {
			await Promise.all([this.getResponsibles()])
		} catch (error) {}
	},
	computed: {
		getBalance() {
			return this.pettyCash.amount - this.pettyCash.expenses
		},
	},
	methods: {
		async closePettyCash() {
			const confirm = await this.showConfirmSwal({
				text: "Finalizar caja chica",
			})
			if (!confirm.isConfirmed) return

			this.isPreloading()
			try {
				const finalized = await PettyCashService.closePettyCash(this.pettyCash.id, this.form)
				this.showSuccessToast("Caja chica finalizado correctamente")
				this.$emit("closed")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
}
</script>
